<template>
  <FilterSelection
    :disabled="disabled"
    :get-item-id="getTeamId"
    :get-item-string-compare="getTeamName"
    v-model="internalValue"
  >
    <template #selection="{ item }">
      <TeamCell v-bind="item" variant="row" />
    </template>
    <AppAutosuggest
      :disabled="disabled"
      :input-props="inputProps"
      :section-configs="sectionConfigs"
      :suggestions="sectionedSuggestions"
      :value="focused ? search : placeholder"
      @input="onInput"
      @selected="onSelect"
      @focus="onFocus"
      @blur="onBlur"
    >
      <template #before-suggestions>
        <Loading v-if="loading" />
        <ErrorAlert v-else-if="error">
          {{ error }}
        </ErrorAlert>
        <InfoAlert v-else-if="!suggestions.length && search.length < 2">
          Enter at least 2 characters to get results
        </InfoAlert>
        <InfoAlert v-else-if="!suggestions.length"> No matching teams </InfoAlert>
      </template>
      <template #default="{ suggestion }">
        <DropdownItem :disabled="disabled" :selected="selectedTeamIds[suggestion.item.id]">
          <TeamCell v-bind="suggestion.item" variant="row" />
        </DropdownItem>
      </template>
    </AppAutosuggest>
  </FilterSelection>
</template>

<script>
import * as Sentry from '@sentry/vue'
import px from 'vue-types'

import axios from '@/axios.js'
import AppAutosuggest from '@/components/generic/Autosuggest.vue'
import FilterSelection from '@/components/Search/filters/FilterSelection.vue'
import TeamCell from '@/components/Table/cells/TeamCell.vue'

import { getEsportTeamsAutocomplete } from '../../../api/autocomplete.js'
import DropdownItem from '../../Form/DropdownItem.vue'
import ErrorAlert from '../../generic/ErrorAlert.vue'
import InfoAlert from '../../generic/InfoAlert.vue'
import Loading from '../../generic/Loading.vue'

export default {
  name: 'TeamFilter',
  components: {
    AppAutosuggest,
    DropdownItem,
    ErrorAlert,
    FilterSelection,
    InfoAlert,
    Loading,
    TeamCell,
  },
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    disabled: px.bool.def(false),
    value: px.arrayOf(px.object),
    excludeTeams: px.arrayOf(px.string).def([]),
  },
  data() {
    return {
      error: null,
      focused: false,
      loading: 0,
      search: '',
      suggestions: [],
    }
  },
  computed: {
    inputProps() {
      return {
        class: 'form-control',
        id: 'team-search-filter',
        placeholder: this.placeholder,
      }
    },

    internalValue: {
      get: function () {
        return this.value || []
      },
      set: function (value) {
        return this.$emit('update', value)
      },
    },

    placeholder() {
      switch (this.internalValue.length) {
        case 0:
          return 'Any team'
        case 1:
          return '1 team selected'
        default:
          return `${this.internalValue.length} teams selected`
      }
    },

    sectionConfigs() {
      return {
        default: {},
        empty: { type: 'div' },
        error: { type: 'div' },
        loading: { type: 'div' },
      }
    },

    sectionedSuggestions() {
      if (this.loading) return [{ name: 'loading', data: [{ loading: true }] }]
      if (this.error) return [{ name: 'error', data: [{ error: this.error }] }]
      if (!this.suggestions.length) return [{ name: 'empty', data: [{ empty: true }] }]
      return [{ data: this.suggestions }]
    },

    selectedTeamIds() {
      return Object.freeze(Object.fromEntries(this.internalValue.map(team => [this.getTeamId(team), true])))
    },
  },
  beforeDestroy() {
    this.cancelRequest()
  },
  methods: {
    cancelRequest() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel()
        this.cancelTokenSource = null
      }
    },
    getTeamId: team => team.id,
    getTeamName: team => team.name,
    async onInput(search) {
      this.search = search
      this.cancelRequest()
      this.suggestions = []
      this.error = null
      if (search.length < 2) return
      this.cancelTokenSource = axios.CancelToken.source()
      this.loading++
      try {
        const data = await getEsportTeamsAutocomplete(search, {
          cancelToken: this.cancelTokenSource.token,
        })
        this.suggestions = Object.freeze(
          (data || []).filter(item => !this.excludeTeams.includes(item.id)).map(item => Object.freeze(item))
        )
      } catch (e) {
        if (axios.isCancel(e)) {
          return
        }
        this.error = axios.extractErrorMessage(e)
        console.error(this.error, e)
        Sentry.captureException(e)
      } finally {
        this.loading--
      }
    },
    onSelect(selection) {
      if (!selection) return
      const { item: team } = selection
      const teamId = this.getTeamId(team)
      this.internalValue = this.selectedTeamIds[teamId]
        ? // remove
          this.internalValue.filter(team => this.getTeamId(team) !== teamId)
        : // add
          [...this.internalValue, team]
      this.search = ''
      this.suggestions = []
    },
    onFocus() {
      this.focused = true
      this.suggestions = []
    },
    onBlur() {
      this.focused = false
      this.search = ''
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .alert {
    margin: 0;
    border-radius: 0;
  }
}
</style>
