<template>
  <div class="container-fluid">
    <b-form @submit.prevent="on_submit" @reset="on_reset">
      <div class="filters">
        <div class="filters--item filters--item__teams">
          <div class="filters--item--title">Opponent teams:</div>
          <TeamFilter :disabled="disabled" v-model="form.teams_vs" :exclude-teams="[team_id]" />
        </div>
        <div v-if="compositions.length" class="filters--item filters--item__agents">
          <div class="filters--item--title">Own compositions:</div>
          <AgentCompositionFilter :disabled="disabled" :compositions="compositions" v-model="form.agent_comps" />
        </div>
        <div class="filters--item filters--item__regions">
          <div class="filters--item--title">Region:</div>
          <RegionFilter :disabled="disabled" v-model="form.platforms" />
        </div>
        <div class="filters--item filters--item__period">
          <div class="filters--item--title">Period:</div>
          <PeriodFilter :disabled="disabled" v-model="filter_period" />
        </div>
        <div class="filters--item filters--item__vod">
          <div class="filters--item--title">Video:</div>
          <b-form-group label-for="filter-has-vod">
            <b-form-checkbox class="mb-1" id="filter-has-vod" v-model="form.has_vod"> only with VOD </b-form-checkbox>
          </b-form-group>
        </div>
      </div>

      <b-row>
        <!-- Search Button -->
        <b-col sm="12">
          <div class="btn-actions">
            <b-form-group>
              <button
                ref="searchBtn"
                type="submit"
                class="btn btn-warning"
                :class="{ highlight: changed }"
                :disabled="disabled"
              >
                Search
              </button>

              <BTooltip v-if="$refs.searchBtn" :show="changed" :target="$refs.searchBtn" triggers="manual">
                Apply new filters
              </BTooltip>

              <b-button type="reset" variant="outline-warning" :disabled="disabled"> Reset </b-button>
            </b-form-group>

            <!-- <b-form-group>
              <b-button variant="outline-warning" @click="savedSearch = !savedSearch">
                <span v-if="savedSearch">
                  <BIconStarFill />
                  Saved search
                </span>
                <span v-else>
                  <BIconStar />
                  Save search
                </span>
              </b-button>
            </b-form-group> -->
          </div>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { BTooltip } from 'bootstrap-vue'
import px from 'vue-types'

import { pxOption } from '../../types.js'
import deepEqual from '../../utils/deepEqual.js'
import { pxNullable } from '../Map/types.js'

import AgentCompositionFilter from './filters/AgentCompositionFilter.vue'
import PeriodFilter from './filters/PeriodFilter.vue'
import RegionFilter from './filters/RegionFilter.vue'
import TeamFilter from './filters/TeamFilter.vue'

export default {
  name: 'SearchFiltersProfile',

  model: {
    prop: 'value',
    event: 'update',
  },

  components: {
    AgentCompositionFilter,
    BTooltip,
    PeriodFilter,
    RegionFilter,
    TeamFilter,
  },

  props: {
    disabled: px.bool.def(false),
    // own compositions
    compositions: px.arrayOf(px.arrayOf(px.string)).def([]),
    // for mixpanel logging - semi-broken
    player_name: px.string,
    // for opponent teams filter
    team_id: px.string,
    // the current query
    value: px.shape({
      map_ids: px.arrayOf(px.string),
      platforms: px.array.def([]),
      teams_vs: px.arrayOf(px.object),
      agent_ids: px.arrayOf(px.string).def([]),
      agent_option: pxOption(),
      agent_vs_ids: px.arrayOf(px.string).def([]),
      agent_vs_option: pxOption(),
      players: px.arrayOf(px.object),
      players_option: pxOption(),
      teams_players: px.arrayOf(px.object),
      teams_players_option: pxOption(),
      period: pxNullable(
        px.shape({
          begin_time: pxNullable(px.string),
          end_time: pxNullable(px.string),
        })
      ),
      has_vod: px.bool,
    }).loose,
  },

  data() {
    return {
      form: {
        ...this.value,
      },
      savedSearch: false,
    }
  },

  computed: {
    changed() {
      return !deepEqual(this.form, this.value)
    },

    filter_period: {
      get() {
        return {
          begin_time: this.form.begin_time,
          end_time: this.form.end_time,
        }
      },
      set({ begin_time, end_time }) {
        this.$set(this.form, 'begin_time', begin_time)
        this.$set(this.form, 'end_time', end_time)
      },
    },
  },

  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(v) {
        this.form = { ...v }
      },
    },
  },

  methods: {
    on_submit() {
      this.$emit('update', this.form)
    },

    on_reset() {
      this.$emit('update', null)
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-actions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 1em 0 0;

  .form-group {
    margin-bottom: 0;
  }
}

.filters {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16em, 1fr));
  grid-gap: 0;

  &--item {
    border: 1px solid #ccc;
    padding: 0.25em 0.5em 0.5em;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    justify-content: space-between;

    &__team-name,
    &__period {
      padding-bottom: calc(1.5rem + 0.5em - 1rem);
    }

    &__maps,
    &__regions,
    &__teams {
      padding-bottom: calc(1.5rem + 0.5em);
    }

    &__period {
      grid-column-end: span 2;
    }

    &--title {
      text-align: center;
      font-size: 1.5em;
    }
  }
}
</style>
