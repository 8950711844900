<template>
  <ApiListController :fetch="fetch" :filter="fetchParams" name="Matches" :per-page.sync="perPageValue">
    <template #default="{ items, total }">
      <component
        v-if="total > 0"
        :is="tableComponent"
        :items="items"
        :highlight-agents="fetchParams.agent_ids"
        :highlight-agents-option="fetchParams.agent_option"
        no-local-sorting
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        @sort-changed="onSortChanged"
        v-bind="$attrs"
        v-on="$listeners"
      />
      <div v-else class="text-muted text-center">
        There are no results matching your filters.
        <router-link :to="{ query: { s: noFiltersSearch } }" class="router-link-active">Try without.</router-link>
      </div>
    </template>
  </ApiListController>
</template>

<script>
import px from 'vue-types'

import { serializeQuery } from '@/utils/searchQuery.js'

import { findMatches } from '../../../api/search.js'
import { DEFAULT_ITEMS_PER_PAGE, ALL_RESULTS_FILTERS } from '../../../store/modules/search.js'
import { pxNullable } from '../../Map/types.js'
import MatchesTable from '../../tables/MatchesTable.vue'
import ApiListController from '../ApiListController.vue'

export default {
  name: 'MatchSearchController',
  components: {
    ApiListController,
  },
  props: {
    filter: px.object,
    perPage: pxNullable(px.integer).def(null),
    table: pxNullable(px.object).def(null),
  },
  data: () => ({
    localPerPage: DEFAULT_ITEMS_PER_PAGE,
    sortBy: 'created_millis',
    sortDesc: true,
    noFiltersSearch: null,
  }),
  computed: {
    fetchNoFiltersSearch() {
      return this.noFiltersSearch === null
    },
    apiSortBy() {
      switch (this.sortBy) {
        case 'created_millis':
          return 'created'
        case 'summary.round_count':
          return 'rounds_played'
        case 'diff':
          return 'rounds_won_diff'
        default:
          return this.sortBy
      }
    },
    fetchParams() {
      return Object.freeze({
        ...this.filter,
        sortBy: this.apiSortBy,
        sortDesc: this.sortDesc,
      })
    },
    perPageValue: {
      get() {
        return this.perPage != null ? this.perPage : this.localPerPage
      },
      set(value) {
        this.localPerPage = value
        this.$emit('update:per-page', value)
      },
    },
    tableComponent() {
      return this.table || MatchesTable
    },
  },
  watch: {
    fetchNoFiltersSearch: {
      async handler(val) {
        if (val) {
          this.noFiltersSearch = await serializeQuery(ALL_RESULTS_FILTERS)
        }
      },
      immediate: true,
    },
  },
  methods: {
    async fetch(params, config) {
      const { matches: data, total } = await findMatches(params, config)
      return { data, total }
    },
    onSortChanged(event) {
      this.sortBy = event.sortBy
      this.sortDesc = event.sortDesc
    },
  },
}
</script>

<style scoped></style>
