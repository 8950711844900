<template>
  <AppTab
    :title-link-attributes="mapActive ? { to: mapPath } : null"
    :title-link-class="{ 'text-muted': !mapActive }"
    @click="onClick"
    title="Map"
  >
    <InfoAlert v-if="grid">Please, select 2 or more matches on same map in order to open 2D Map</InfoAlert>
    <InfoAlert v-else>Please, select 2 or more matches of a team on same map in order to open 2D Map</InfoAlert>
  </AppTab>
</template>

<script>
import px from 'vue-types'
import { mapGetters } from 'vuex'

import mixpanel from '@/mixpanel.js'

import InfoAlert from '../generic/InfoAlert.vue'
import AppTab from '../generic/Tab.vue'

export default {
  name: 'MapTab',
  components: { InfoAlert, AppTab },
  props: {
    active: Boolean,
    matches: px.arrayOf(px.shape({}).loose),
    scrim: Boolean,
    grid: px.bool.def(false),
    team: String,
    trackingContext: px.object,
    apiKey: px.string,
  },
  computed: {
    ...mapGetters({
      getMapById: 'static/getMapById',
    }),

    count() {
      return this.matches ? this.matches.length : 0
    },
    mapInternal() {
      const teams = {}
      const maps = {}
      if (this.matches) {
        this.matches.forEach(match => {
          if (!match) return
          teams[match.first_team?.id || match.teams[0].id] = (teams[match.first_team?.id || match.teams[0].id] || 0) + 1
          teams[match.second_team?.id || match.teams[1].id] =
            (teams[match.second_team?.id || match.teams[1].id] || 0) + 1
          maps[match.map] = (maps[match.map] || 0) + 1
        })
      }
      const [team] = Object.entries(teams).find(([, matches]) => matches === this.count) || []
      const hasOneMap = Object.keys(maps).length === 1
      return {
        team,
        map: hasOneMap ? Object.keys(maps).pop() : null,
        matches: this.matches || [],
      }
    },
    mapActive() {
      const { team, map } = this.mapInternal
      return map && (this.grid || team) && this.count >= 2
    },
    mapPath() {
      const { team, map, matches } = this.mapInternal
      if (this.scrim) {
        return { path: `/scrim/${matches.map(match => match.id).join('.')}/map` }
      }
      if (this.grid) {
        return {
          path: `/grid/map/${matches.map(match => match.seriesId).join(',')}/${this.getMapById(map).name}`,
          query: {
            key: this.apiKey || undefined,
          },
        }
      }
      return { path: `/map/${this.getMapById(map).name}/${team}`, query: { matches: matches.map(match => match.id) } }
    },
  },
  watch: {
    mapInternal({ team, map }) {
      this.$emit('update:map', map)
      this.$emit('update:team', team)
    },
    mapActive(val) {
      this.$emit('update:active', val)
    },
  },
  methods: {
    onClick(event) {
      if (this.mapActive) {
        event.preventDefault()
        this.trackClick(event)
      }
    },
    trackClick($event) {
      mixpanel.track_link({ type: 'maptool', href: $event.target.href }, this.trackingContext)
    },
  },
}
</script>

<style scoped></style>
