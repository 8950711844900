<template>
  <FilterSelection :disabled="disabled" :get-item-string-compare="getCompositionName" v-model="internalValue">
    <template #selection="{ item }">
      <AgentComposition :agent_ids="item" />
    </template>
    <FormDropdown
      :options="options"
      :disabled="disabled"
      text-no-selection="Any agent composition"
      text-single-selection="1 agent composition selected"
      :text-multiple-selection="`${internalValue.length} compositions selected`"
      v-model="internalValue"
    >
      <DropdownItem
        v-for="(composition, index) in displayCompositions"
        :key="index"
        :selected="isCompositionActive(composition)"
        :disabled="disabled"
        @click.native="toggle(composition)"
      >
        <div class="composition-container">
          <AgentComposition :agent_ids="composition" />
          <div v-if="composition.picks" class="ml-2">
            <strong>{{ composition.picks }}</strong>
            <span class="small ml-1">{{ composition.picks | percentage(totalPicks) }}</span>
          </div>
        </div>
      </DropdownItem>
    </FormDropdown>
  </FilterSelection>
</template>

<script>
import px from 'vue-types'

import DropdownItem from '@/components/Form/DropdownItem.vue'
import percentage from '@/filters/percentage'
import getCompositionHash from '@/utils/agentCompositionHash'

import { deepFreeze } from '../../../helper.js'
import FormDropdown from '../../Form/Dropdown.vue'
import AgentComposition from '../../UI/AgentComposition.vue'

import FilterSelection from './FilterSelection.vue'
export default {
  name: 'AgentCompositionFilter',
  components: { FormDropdown, AgentComposition, FilterSelection, DropdownItem },
  model: {
    prop: 'value',
    event: 'update',
  },
  props: {
    agentsFallback: px.bool.def(true),
    compositions: px.arrayOf(px.arrayOf(px.string)).def(Object.freeze([])).isRequired,
    disabled: px.bool.def(false),
    value: px.arrayOf(px.arrayOf(px.string)),
  },
  data: () => ({
    // temporary until api support options
    option: 'and',
  }),
  computed: {
    displayCompositions() {
      return [...(this.compositions || [])].sort((a, b) =>
        this.getCompositionName(a).localeCompare(this.getCompositionName(b))
      )
    },

    internalValue: {
      get: function () {
        return this.value || []
      },
      set: function (value) {
        return this.$emit('update', value)
      },
    },

    options() {
      return this.agentFallback
        ? deepFreeze(
            this.$store.getters['static/agents'].map(a => ({
              text: a.name,
              value: a.id,
            }))
          )
        : undefined
    },

    selectedCompositionsHashIdx() {
      return Object.freeze(Object.fromEntries(this.internalValue.map((c, idx) => [this.getCompositionHash(c), idx])))
    },

    totalPicks() {
      return this.compositions.reduce((sum, composition) => sum + composition.picks, 0)
    },
  },
  filters: {
    percentage,
  },
  methods: {
    getCompositionHash,
    getCompositionIdx(composition) {
      return this.selectedCompositionsHashIdx[this.getCompositionHash(composition)]
    },
    getCompositionName(composition) {
      return composition
        .map(this.$store.getters['static/getAgentById'])
        .map(agent => agent.name)
        .join('')
    },
    isCompositionActive(composition) {
      return this.getCompositionIdx(composition) != null
    },
    toggle(composition) {
      const idx = this.getCompositionIdx(composition)
      if (idx != null) {
        this.internalValue = [...this.internalValue.slice(0, idx), ...this.internalValue.slice(idx + 1)]
      } else {
        this.internalValue = [...this.internalValue, composition]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.composition-container {
  display: flex;
  align-items: center;
}
</style>
